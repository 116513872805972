import React, { useEffect, useState } from 'react';
import ComputirTitle, {
  ResponsiveComputirTitle,
} from '../../style/ComputirTitle';
import { Typography, Button, Icon, Box } from '@mui/material';
import ComputerIcon from '@mui/icons-material/Computer';
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import IconConnection from './IconConnection';
import SignUpDialog from './SignUpDialog';
import loginWithTags from '../../components/shared/loginWithTags';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutHandler from '../../components/shared/logoutHandler';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

function BetaClosedButton({
  buttonAction,
  buttonText,
  buttonVariant = 'contained',
}) {
  return (
    <Button
      variant={buttonVariant}
      color="secondary"
      className="head-section-2-button"
      onClick={() => buttonAction()}
      sx={{ width: '60%' }}
    >
      {buttonText}
    </Button>
  );
}

function BottomText() {
  return (
    <Box
      sx={{
        width: '100%',
        // backgroundColor: 'orange',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0% 4% 0% 4%',
        marginTop: { xs: '5%', sm: '1%' },
        marginBottom: { xs: '2%', sm: '0%' },
      }}
    >
      <Typography textAlign={'center'}>
        Computir's BETA 0.0.0 is now closed! Thanks to our great community for
        all of the feedback, stay tuned for the release of Computir's newest
        0.0.1 platform!
      </Typography>
    </Box>
  );
}

function LoadingBar() {
  return (
    <div
      style={{
        width: '100%',
        // backgroundColor: 'purple',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0% 10% 0% 10%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          id="top-bar"
          style={{
            width: '90%',
            height: '50px',
            // backgroundColor: 'purple',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>Loading...</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/beta.png"
              height="35"
              alt="beta callout"
              style={{
                borderRadius: '5%',
              }}
            />
            <Typography>&nbsp;&nbsp;0.0.1</Typography>
          </div>
        </div>
        <div style={{ height: '75px' }}>
          <LoadingBarSvg />
        </div>
      </Box>
    </div>
  );
}

function LoadingBarSvg() {
  return (
    <svg width="300" height="75" xmlns="http://www.w3.org/2000/svg">
      {/* <!-- Outer square (red outline) --> */}
      <rect
        x="10"
        y="10"
        width="280"
        height="45"
        fill="none"
        stroke="white"
        stroke-width="5"
      />

      {/* <!-- Inner square (black 80%) --> */}
      <rect x="10" y="10" width="224" height="45" fill="black" />

      {/* <!-- Checkerboard pattern (remaining 20%) --> */}
      <g transform="translate(234, 10)">
        <rect width="56" height="45" fill="gray" />
        <g>
          <rect width="10.8" height="9" fill="lightgray" />
          <rect x="10.8" width="10.8" height="9" fill="darkgray" />
          <rect y="9" width="10.8" height="9" fill="darkgray" />
          <rect x="10.8" y="9" width="10.8" height="9" fill="lightgray" />
          <rect x="0" y="18" width="10.8" height="9" fill="lightgray" />
          <rect x="10.8" y="18" width="10.8" height="9" fill="darkgray" />
          <rect y="27" width="10.8" height="9" fill="darkgray" />
          <rect x="10.8" y="27" width="10.8" height="9" fill="lightgray" />
          <rect x="0" y="36" width="10.8" height="9" fill="lightgray" />
          <rect x="10.8" y="36" width="10.8" height="9" fill="darkgray" />

          {/* <!-- Blinking effect --> */}
          <animate
            attributeName="opacity"
            values="1;0;1"
            dur="1s"
            repeatCount="indefinite"
          />
        </g>
      </g>
    </svg>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BetaClosed() {
  const location = useLocation();
  const [signUpDialogOpen, setSignUpDialogOpen] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  // monitor for if it's sent to '/signUpDisabled' and if so, communicate why logged out
  useEffect(() => {
    if (location.pathname === '/signUpDisabled') {
      handleSnackbarOpen(
        'Sorry, our beta is closed and we are no longer accepting new users!',
      );
    }
  }, [location]);
  return (
    <>
      <LogoutHandler onLogout={handleSnackbarOpen} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="warning">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          // backgroundColor: 'red',
          // height: '100%',
          padding: { xs: '13% 3% 3% 3%', sm: '2% 3% 1% 3%' },
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            // backgroundColor: 'blue',
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            padding: '0% 2% 0% 2%',
          }}
        >
          <ResponsiveComputirTitle />
          <Box
            sx={{
              // backgroundColor: 'green',
              marginTop: { xs: '5%', sm: '1%' },
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography variant="h4" textAlign={'center'}>
                Turn your Computer into a top-of-the-line gaming rig.
              </Typography>
              <Typography
                variant="h4"
                textAlign={'center'}
                sx={{ marginTop: { xs: '6%', sm: '1%' } }}
              >
                Turn it into a Computir.
              </Typography>
            </Box>
            <br />
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // backgroundColor: 'orange',
                  width: { xs: '100%', sm: '100%' },
                  alignItems: 'center',
                }}
              >
                <IconConnection />
                <BetaClosedButton
                  buttonText="Sign up for Email Alerts"
                  buttonAction={() => setSignUpDialogOpen(true)}
                />
              </Box>
              <SignUpDialog
                signUpDialogOpen={signUpDialogOpen}
                setSignUpDialogOpen={setSignUpDialogOpen}
              />
              <br />
              <br />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <LoadingBar />
                <BottomText />
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '60%' },
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <BetaClosedButton
                buttonText="Existing Users Sign In"
                buttonAction={() => loginWithTags(loginWithRedirect)}
                buttonVariant="outlined"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
