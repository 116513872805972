// typography: {
//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
// },

// Supports weights 300-700
// import '@fontsource/space-grotesk/300.css';
import '@fontsource/space-grotesk/400.css';

export const mainBlue = '#368edb';
export const mainBlack = '#000000';
export const darkGray = '#292929';

export const getDesignTokens = (mode) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#2b2b2b',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#6b6b6b',
            minHeight: 24,
            border: '3px solid #2b2b2b',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Space Grotesk'].join(','),
    // TODO - somehow add fontFamily: 'Montserrat' so it can be used for some without overriding all here
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          cardBackground: {
            default: '#FFFFFF',
            secondary: '#AFAFAF',
          },
          transparentCardBackground: {
            default: darkGray,
          },
          background: {
            default: '#FFFFFF',
            header: '#D8D8D8',
            inverse: '#09090A',
          },
          primary: {
            main: mainBlue,
            error: '#f44336',
          },
          secondary: {
            main: mainBlue,
          },
          text: {
            primary: '#09090A',
            secondary: mainBlue,
            dark: mainBlack,
          },
        }
      : {
          cardBackground: {
            default: '#09090A',
            secondary: '#303030',
          },
          transparentCardBackground: {
            default: darkGray,
          },
          background: {
            default: '#09090A',
            header: '#020202',
            inverse: '#FFFFFF',
          },
          primary: {
            main: mainBlue,
            error: '#f44336',
          },
          secondary: {
            main: mainBlue,
          },
          text: {
            // 0FB6C2
            primary: '#FFFFFF',
            secondary: mainBlue,
            dark: mainBlack,
          },
        }),
  },
});

export const generateFontSizes = (theme) => {
  theme.typography.h6 = {
    fontSize: '1.0rem',
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
    },
  };
  theme.typography.h4 = {
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '1.235',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.215rem',
      fontWeight: 'normal',
      lineHeight: '1.235',
    },
  };
  theme.typography.h3 = {
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: '1.235',
    fontFamily: 'Montserrat!important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      fontWeight: '700',
      lineHeight: '1.167',
      fontFamily: 'Montserrat!important',
    },
  };
  theme.typography.subtitle1 = {
    fontSize: '0.5rem',
    fontWeight: 'lighter',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.7rem',
      fontWeight: 'lighter',
    },
  };

  return theme;
};

// let theme = createTheme({
//   palette: {
//     // dark palette settings
//     mode: 'dark',
//     cardBackground: {
//       default: "#09090A",
//       secondary: "#303030"
//     },
//     transparentCardBackground: {
//       default: "#292929"
//     },
//     background: {
//       default: "#09090A",
//       header: '#020202'
//     },
//     primary: {
//       main: '#0FB6C2',
//     },
//     secondary: {
//       main: '#f16d1b',
//     },
//     text: {
//       primary: '#0FB6C2',
//       secondary: '#f16d1b',
//       dark: '#000000',
//     },
//     // light palette settings
//     mode: 'light',
//     cardBackground: {
//       default: "#FFFFFF",
//       secondary: "#D8D8D8"
//     },
//     transparentCardBackground: {
//       default: "#292929"
//     },
//     background: {
//       default: "#FFFFFF",
//       header: '#D8D8D8'
//     },
//     primary: {
//       main: '#09090A',
//     },
//     secondary: {
//       main: '#f16d1b',
//     },
//     text: {
//       primary: '#09090A',
//       secondary: '#f16d1b',
//       dark: '#000000',
//     }
//   }
//   });

// theme = createTheme(theme, {
//   components: {
//     // Name of the component
//     // MuiPaper: {
//     //   styleOverrides: {
//     //     // Name of the slot
//     //     root: {
//     //       // Some CSS
//     //       backgroundColor: theme.palette.background.default,
//     //       color: theme.palette.text.primary,
//     //       boxShadow: 'none',
//     //     },
//     //   },
//     // },
//   },
//   })

// theme = createTheme(theme, {
//   overrides: {
//     MuiPaper: {
//       root: {
//         color: 'red'
//       }
//     }
//   }
// })

// export default theme;
