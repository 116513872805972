import React from 'react';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ComputirTitle from '../../style/ComputirTitle';

function LegalLink(props) {
  let history = useHistory();
  return (
    <Button
      disableRipple={true}
      sx={{
        background: 'none!important',
        border: 'none',
        padding: '0!important',
        color: 'text.primary',
        textDecoration: props.underline,
        cursor: 'pointer',
        textTransform: 'none',
        fontSize: '9px',
        minWidth: '0px',
      }}
      onClick={() => {
        history.push(props.windowLocationHash);
      }}
    >
      {props.buttonText}
    </Button>
  );
}

export const LogoWithSocials = () => {
  return (
    <Box>
      <Box>
        <Box sx={{ marginBottom: '2%', textAlign: 'center' }}>
          <IconButton
            onClick={() => {
              window.open(
                'https://www.facebook.com/people/Computir/100089570655969/',
                '_blank',
              );
            }}
          >
            <FacebookIcon color={'secondary'} />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open('https://www.instagram.com/computir_inc/', '_blank');
            }}
          >
            <InstagramIcon color={'secondary'} />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open('https://twitter.com/computir_inc', '_blank');
            }}
          >
            <TwitterIcon color={'secondary'} />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open('https://www.reddit.com/r/computir/', '_blank');
            }}
          >
            <RedditIcon color={'secondary'} />
          </IconButton>
        </Box>
      </Box>
      <LegalLink
        buttonText="Legal Terms of Service"
        windowLocationHash="legal"
      />
      <Typography display={'inline'} sx={{ fontSize: '9px' }}>
        &nbsp;|&nbsp;
      </Typography>
      <LegalLink buttonText="Privacy Policy" windowLocationHash="privacy" />
    </Box>
  );
};

const BetaClosedFooter = ({ theme, colorMode }) => {
  return (
    <FooterContainer>
      <LogoAndThemeButtonContainer>
        <LogoWithSocials theme={theme} colorMode={colorMode} />
      </LogoAndThemeButtonContainer>
      <br />
    </FooterContainer>
  );
};
export default BetaClosedFooter;
// justify-content: space-between;
const LogoAndThemeButtonContainer = styled.div`
  display: flex;
`;

export const FooterContainer = styled.div`
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0px 0px 0px 50px;
`;

// display: flex;
// flex-direction: column;
// justify-content: center;
// margin: 0 auto;
export const ColumnContainer = styled.div`
  padding-left: 10%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10%;
`;

export const Row = styled.div`
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  color: text.primary;
  margin-bottom: 15%;
  font-weight: bold;
`;

/**
 *  @param {string} text
 *  @param {string} toLink
 */
export const FooterLink = (props) => {
  return <StyledLink href={`/${props.toLink}`}>{props.text}</StyledLink>;
};

const StyledLink = styled.a`
  margin-bottom: 5%;
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: gray;
  }
  color: inherit;
`;

// export const FooterLink = styled.a`
//   color: #fff;
//   margin-bottom: 20px;
//   font-size: 18px;
//   text-decoration: none;
//   cursor: pointer;

//   &:hover {
//       color: green;
//       transition: 200ms ease-in;
//   }
// `;
