import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutHandler = ({ onLogout }) => {
  const location = useLocation();
  const { logout } = useAuth0();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authenticated = params.get('authenticated');
    const error = params.get('error');
    const errorDescription = params.get('error_description');

    // Check for query parameters
    if (authenticated === 'true' && error === 'access_denied') {
      console.log(errorDescription);
      // Handle logout logic, like clearing tokens and redirecting
      //   error_description=Social%20sign-ups%20are%20not%20allowed.
      console.log('Logging out due to error:', error);

      // Clear user session (e.g., remove tokens from local storage)
      localStorage.removeItem('accessToken'); // Adjust based on your storage mechanism
      localStorage.removeItem('refreshToken');

      // Redirect to the login page or home page
      logout({ returnTo: `${window.location.origin}/signUpDisabled` });
      // Trigger the Snackbar from the parent after a short delay
      //   onLogout('You are being logged out due to an error!');
    }
  }, [location, onLogout]);

  return null; // This component doesn't render anything
};

export default LogoutHandler;
