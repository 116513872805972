import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import NewsLetterSignUp from '../../components/NewsLetterSignUp';
import { Typography } from '@mui/material';

export default function SignUpDialog({
  signUpDialogOpen,
  setSignUpDialogOpen,
}) {
  const handleClose = () => {
    setSignUpDialogOpen(false);
  };
  return (
    <Dialog
      open={signUpDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant={'h4'}>Stay in the loop!</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: 'text.primary' }}
        >
          Be the first to know when the next phase of our beta goes live! Sign
          up now for exclusive alerts and updates. Join our community of early
          adopters and help shape the future of our product.
        </DialogContentText>
        <NewsLetterSignUp
          customHeader="Don't miss out—sign up today!"
          reason="betaClosedInfoSignUp"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          No thanks
        </Button>
      </DialogActions>
    </Dialog>
  );
}
