import React from 'react';
import { Route } from 'react-router-dom';
import ComputersPage from './pages/computers/ComputersPage';
import CreateComputerPage from './pages/create-computer/CreateComputerPage';
import HomePage from './pages/homepage/HomePage';
import Profile from './pages/profile/Profile';
import BillingPage from './pages/billing/BillingPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FaqPage from './pages/faq/FaqPage';
import CssBaseline from '@mui/material/CssBaseline';
import PaymentSuccessPage from './pages/payment/PaymentSuccessPage';
import CommonHeader from './components/Header';
import CommonFooter from './components/Footer';
import { ComputersWrapper } from './style/ComputersContainer';
// import TestimonialsPage from './pages/testimonials/TestimonialsPage';
// import BlogPage from './pages/blog/Blog';
import AboutPage from './pages/about/AboutPage';
import CareersPage from './pages/careers/Careers';
import SpecsPage from './pages/specsPage/SpecsPage';
import SupportPage from './pages/support/SupportPage';
import GettingStarted from './pages/gettingStarted/GettingStarted';
import Privacy from './pages/legal/privacy';
import Terms from './pages/legal/terms';
import PreFooter from './components/PreFooter';
import { generateFontSizes, getDesignTokens } from './theme';
import MobileHeader from './components/mobile/MobileHeader';
import MobileFooter from './components/mobile/MobileFooter';
// import CookieConsent from './components/CookieConsent';
import { HelmetProvider } from 'react-helmet-async';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import BetaClosed from './pages/betaClosed/betaClosed';
import BetaClosedFooter from './pages/betaClosed/BetaClosedFooter';
import BetaClosedMobileFooter from './pages/betaClosed/BetaClosedFooterMobile';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const getLocalStorageTheme = () => {
  const localStorageValue = localStorage.getItem('theme');
  if (localStorageValue === null) {
    return 'dark';
  } else {
    return localStorageValue;
  }
};

function App() {
  const initialState = getLocalStorageTheme();
  const { isAuthenticated } = useAuth0();

  const [mode, setMode] = React.useState(initialState);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  let theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  theme = generateFontSizes(theme);
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <HelmetProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          {localStorage.setItem('theme', mode)}
          <CssBaseline />
          <>
            {isAuthenticated ? (
              <ComputersWrapper>
                {mobileBreakpoint ? <CommonHeader /> : <MobileHeader />}
                <div style={{ marginBottom: '30px' }}>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/computirs" component={ComputersPage} />
                  <Route exact path="/create" component={CreateComputerPage} />
                  <Route exact path="/faq" component={FaqPage} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/billing" component={BillingPage} />
                  <Route
                    exact
                    path="/paymentsuccess"
                    component={PaymentSuccessPage}
                  />
                  <Route exact path="/about" component={AboutPage} />
                  <Route
                    exact
                    path="/gettingstarted"
                    component={GettingStarted}
                  />
                  <Route exact path="/careers" component={CareersPage} />
                  <Route exact path="/support" component={SupportPage} />
                  <Route exact path="/specs" component={SpecsPage} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/legal" component={Terms} />
                </div>
                <PreFooter />
                {mobileBreakpoint ? (
                  <CommonFooter theme={theme} colorMode={colorMode} />
                ) : (
                  <MobileFooter />
                )}
              </ComputersWrapper>
            ) : (
              <ComputersWrapper>
                <div style={{ marginBottom: '30px', height: '100vh' }}>
                  <Route exact path="/" component={BetaClosed} />
                  <Route exact path="/signUpDisabled" component={BetaClosed} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/legal" component={Terms} />
                  {mobileBreakpoint ? (
                    <BetaClosedFooter />
                  ) : (
                    <BetaClosedMobileFooter />
                  )}
                </div>
              </ComputersWrapper>
            )}

            {/* <CookieConsent /> */}
          </>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </HelmetProvider>
  );
}

export default App;
