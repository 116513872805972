import React from 'react';
import { Box } from '@mui/material';
// transform: "rotate(45deg)"

export default function ComputirTitle() {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
    >
      <img src="/logo.png" height="50" alt="logo" />
      <img
        src="/beta.png"
        height="17"
        alt="beta callout"
        style={{
          borderRadius: '5%',
          position: 'absolute',
          left: '45px',
          bottom: '32px',
        }}
      />
    </div>
  );
}

export function ResponsiveComputirTitle() {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
    >
      <Box
        component="img"
        sx={{
          content: `url(${window.location.origin + '/logo.png'})`,
          height: { xs: '50px', sm: '120px' },
        }}
        alt="logo"
      />
      <Box
        component="img"
        sx={{
          position: 'absolute',
          content: `url(${window.location.origin + '/beta.png'})`,
          height: { xs: '17px', sm: '40px' },
          left: { xs: '45px', sm: '111px' },
          bottom: { xs: '31px', sm: '74px' },
        }}
        alt="beta callout"
      />
    </div>
  );
}
